.log h3 {
  color: rgb(173, 173, 173);
  margin-left: 0;
  padding-left: 0;
  font-size: 1.5rem;
}

.butt {
  background-color: #32B9CF;
  box-shadow: 0px 0px 8px 0px rgba(255, 255, 255, 0.5);
  border: none;
}

.butt:hover {
  background-color: rgb(189, 55, 39 );
  box-shadow: 0px 0px 8px 0px white;
  border: none;
}
