span.page-link {
  cursor: pointer;
}
span.page-link {
  cursor: pointer;
}

.page-item.active .page-link {
  background-color: rgb(173, 22, 22  ) ;
  border: none;
}
.page-link {
  background-color: rgb(173, 22, 22  ) ;
  border: none;
}

