/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.sidebar {
  z-index: 990;
  display: inline-block;
  height: 100vh;
  right: 0;
  top: 0;
  padding-bottom: 40px;
  min-width: 250px;
  max-width: 250px;
  background: lightgrey;
  color: #fff;
  /*border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;*/
  height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  box-shadow: 0 12px 30px rgb(80 143 244 / 10%);
  transition: all 0.4s;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
}
.sidebar::-webkit-scrollbar:hover {
  background-color: rgb(255, 255, 255);
}
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}
.sidebar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.asideButton {
  right: 260px;
  top: 25px;
  position: fixed;
  z-index: 900;
  transition: all 0.4s;
  border-radius: 50%;
  background-color: honeydew;
  color: rgb(165, 12, 12 );
  box-shadow: 0px 0px 8px 0px rgb(73, 67, 67 ) !important;
}

.asideButton.activeButton {
  right: 20px;
  transition: all 0.5s;
}

.sidebar.active {
  margin-right: -250px;
  transition: all 0.4s;
}

.sidebar .sidebar-header {
  padding: 10px;
  padding-left: 25px;
  background: lightgrey;
  /*border-top-right-radius: 8px;*/
}

.sidebar ul li a {
  padding: 10px;
  padding-left: 20px;
  font-size: 1rem;
  display: block;
  color: black;
}

.sidebar ul li a i {
  color: rgb(165, 12, 12 );
  font-size: 0.8rem;
  padding: 3px;
  align-items: flex-end;
}

.sidebar ul li a:hover {
  color:  rgb(165, 12, 12 );
  background: rgba(255, 255, 255, 0.637);
  font-weight: bolder;
  text-decoration: none;
}

hr {
  margin: 10px;
  border: 0.5px solid rgb(255, 255, 255);
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }
  #sidebar.active {
    margin-left: 0;
  }
  #sidebarCollapse span {
    display: none;
  }
}

.backAside {
  background-color: rgba(121, 123, 124, 0.459);
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 200;
}
