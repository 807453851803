.modal-up {
  margin-top: 100px;
  z-index: 1000;
  width: 60rem;
  height: 50rem;
}

.title-modal {
  font-family: 'Abel', sans-serif;
  color:  rgb(165, 12, 12 );
  font-size: 1.8rem;
}

.modal-header.primary {
  color: #fff;
  background-color: rgb(173, 22, 22  ) ;
  border-color: #337ab7;
}

.modal-content.primary {
  color: #fff;
  background-color: lightgrey;
  box-shadow: 0px 0px 8px 0px rgb(73, 67, 67 );
}
